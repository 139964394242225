import { navigate } from "gatsby"
import React from "react"
import Layout from "../components/common/body/layout"

export default function Home() {
  navigate("/")
  return (
    <div>
      <div className="py-52 text-center text-white">
        <h1 className="mb-4 text-3xl font-bold">404, redirecting...</h1>
      </div>
    </div>
  )
}
