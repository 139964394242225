import React from "react"

const Hamburger = ({ label }) => {
  return (
    <button
      className="peer group relative h-5 w-6 cursor-pointer outline-none duration-100 ease-in-out hover:rotate-180 hover:animate-spin focus:rotate-180 focus:animate-spin"
      aria-label={label}
    >
      <div className="rotate absolute top-0 h-1 w-full transform-gpu rounded-full bg-primary duration-300 group-hover:translate-y-2 group-hover:rotate-60 group-focus:translate-y-2 group-focus:rotate-60"></div>
      <div className="absolute top-2 h-1 w-full rounded-full bg-primary"></div>
      <div className="absolute top-4 h-1 w-full transform-gpu rounded-full bg-primary duration-300 group-hover:-translate-y-2 group-hover:-rotate-60 group-focus:-translate-y-2 group-focus:-rotate-60"></div>
    </button>
  )
}

export default Hamburger
