import React from "react"
import { Link } from "gatsby"
import logo from "../../../images/logo.svg"

const Footer = ({ contactLink, title, strings }) => {
  return (
    <footer className="flex snap-start snap-normal items-center justify-center border-t bg-gray-50 p-4 dark:bg-gray-800 md:px-6 md:py-8">
      <div className="flex w-full max-w-5xl flex-col items-center gap-6">
        <div className="flex w-full flex-col items-center justify-between sm:flex-row">
          <Link
            to="/"
            title={strings.goHome}
            className="mb-4 flex flex-row items-center sm:mb-0"
          >
            <img src={logo} alt={title} className="mr-3 h-8" />
            <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
              {title}
            </span>
          </Link>
          <ul className="mb-6 flex flex-wrap items-center gap-4 text-sm text-gray-500 dark:text-gray-400 sm:mb-0">
            <li>
              <Link
                to={strings.about.link}
                title={strings.about.label}
                className="group transform-gpu outline-none transition duration-200 ease-in-out hover:font-semibold hover:text-primary focus:font-semibold focus:text-primary active:text-disco"
              >
                {strings.about.label}
                <span className="block -translate-x-1/2 scale-x-0 transform-gpu border-b border-primary transition duration-200 ease-in active:border-disco group-focus-within:translate-x-0 group-focus-within:scale-100 group-hover:translate-x-0 group-hover:scale-x-100"></span>
              </Link>
            </li>
            <li>
              <a
                href={contactLink.link}
                target="_blank"
                rel="noreferrer"
                title={contactLink.label}
                className="group transform-gpu outline-none transition duration-200 ease-in-out hover:font-semibold hover:text-primary focus:font-semibold focus:text-primary active:text-disco"
              >
                {contactLink.label}
                <span className="block -translate-x-1/2 scale-x-0 transform-gpu border-b border-primary transition duration-200 ease-in active:border-disco group-focus-within:translate-x-0 group-focus-within:scale-100 group-hover:translate-x-0 group-hover:scale-x-100"></span>
              </a>
            </li>
          </ul>
        </div>
        <hr className="w-full border-gray-200 dark:border-gray-700 sm:mx-auto" />
        <span className="flex w-full flex-row justify-center text-sm text-gray-500 dark:text-gray-400 sm:text-center">
          <span className="mr-1">© 2022</span>
          <Link
            to="/"
            title={strings.goHome}
            className="group transform-gpu outline-none transition duration-200 ease-in-out hover:font-semibold hover:text-primary focus:font-semibold focus:text-primary active:text-disco"
          >
            {title}
            <span className="block -translate-x-1/2 scale-x-0 transform-gpu border-b border-primary transition duration-200 ease-in active:border-disco group-focus-within:translate-x-0 group-focus-within:scale-100 group-hover:translate-x-0 group-hover:scale-x-100"></span>
          </Link>
          <span>. {strings.copyright}.</span>
        </span>
      </div>
    </footer>
  )
}

export default Footer
