import React from "react"
import MenuItem from "./menuItem"
import Logo from "../utils/logo"
import { Link } from "gatsby"
import Hamburger from "./hamburger"
import MobileMenuItem from "./mobileMenuItem"

const Header = ({
  solutions,
  contactLink,
  title,
  strings,
  solutionsHeader,
}) => {
  /** TODO: Localize the solution links */
  const items = [
    {
      text: solutionsHeader,
      options: solutions
        .filter(solution => solution.shown)
        .map(solution => ({
          name: solution.name,
          link: solution.released ? `/solutions/${solution.slug}` : undefined,
        })),
    },
    {
      text: strings.about.label,
      link: strings.about.link,
      outer: true,
    },
  ]
  return (
    <div className="z-50 flex h-20 w-full snap-start snap-normal items-center justify-center space-x-20 rounded-lg border-b-2 border-primary py-4 px-8 align-middle text-2xl text-black shadow-solid-md">
      <div className="flex w-full max-w-5xl flex-row gap-8">
        {/** Logo */}
        <Link
          to="/"
          title={strings.goHome}
          className="py-auto flex h-full w-min flex-row items-center justify-start gap-3 text-center"
        >
          <div className="w-12 p-1 md:w-12">
            <Logo />
          </div>
          <div className="flex h-full w-max translate-y-1 flex-col items-end">
            <span className="text-2xl font-bold leading-tight">{title}</span>
          </div>
        </Link>
        {/** Web menu */}
        <div className="hidden w-full translate-y-1 items-center md:flex">
          <div className="flex items-center justify-start space-x-16">
            {items.map(item => (
              <MenuItem
                text={item.text}
                link={item.link}
                options={item.options}
                outer={item.outer}
              />
            ))}
          </div>
          <div className="flex w-full justify-end space-x-16">
            <MenuItem
              text={contactLink.label}
              link={contactLink.link}
              outer={true}
            />
          </div>
        </div>
        {/** Mobile menu */}
        <div className="flex w-full justify-end md:hidden">
          <div className="relative flex items-center">
            <Hamburger label={strings.openMenu} />
            <div className="absolute right-0 top-full w-max scale-0 transform-gpu pb-12 opacity-0 duration-200 focus-within:flex focus-within:scale-100 focus-within:opacity-100 hover:flex hover:scale-100 hover:opacity-100 peer-hover:flex peer-hover:scale-100 peer-hover:opacity-100 peer-focus:flex peer-focus:scale-100 peer-focus:opacity-100">
              <div className="mt-2 flex w-max flex-col space-y-3 rounded-md border-2 border-primary bg-white px-4 py-2 shadow-solid-md">
                {items.map(item => (
                  <MobileMenuItem
                    text={item.text}
                    link={item.link}
                    options={item.options}
                  />
                ))}
                <MobileMenuItem
                  text={contactLink.label}
                  link={contactLink.link}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
