import React, { useState, cloneElement, Children } from "react"
import Header from "../header/header"
import Footer from "../footer"

const Layout = ({
  children,
  site,
  solutions,
  contactLink,
  strings,
  solutionsHeader,
  showModal,
  fixed,
}) => {
  // TODO: Replace the domain
  return (
    <div className="flex min-h-screen flex-col overflow-hidden scroll-smooth font-baloo">
      <main
        className={`${showModal ? "h-screen" : ""} ${
          fixed ? "max-h-screen" : ""
        } flex min-h-screen snap-y flex-col justify-between overflow-hidden selection:bg-primary selection:text-white`}
      >
        <div className="z-10 bg-white">
          <Header
            solutions={solutions}
            contactLink={contactLink}
            title={site}
            strings={strings}
            solutionsHeader={solutionsHeader}
          />
        </div>
        <section className="flex grow flex-col">{children}</section>
        <div>
          <Footer contactLink={contactLink} title={site} strings={strings} />
        </div>
      </main>
    </div>
  )
}

export default Layout
