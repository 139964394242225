import React from "react"
import { Link } from "gatsby"

const MobileMenuItem = ({ text, link, options }) => {
  const Tag = link
    ? Link
    : ({ children, className }) => <div className={className}>{children}</div>
  const MegaTag = link
    ? ({ children, className }) => <div className={className}>{children}</div>
    : ({ children, className }) => (
        <button className={className}>{children}</button>
      )
  return (
    <MegaTag className="group flex flex-col w-max outline-none cursor-pointer">
      <Tag
        className="text-lg text-start bg-white transition-all transform-gpu duration-200 group-hover:scale-105 group-hover:font-bold group-hover:text-primary group-focus:scale-105 group-focus:font-bold group-focus:text-primary group-focus:underline focus:scale-105 focus:font-bold focus:text-primary focus-visible:underline outline-none"
        to={link}
        title={text}
      >
        {text}
      </Tag>
      {options && (
        <div className="transition-all transform-gpu duration-200 h-0 opacity-0 group-hover:h-auto flex group-hover:opacity-100 group-focus:h-auto group-focus:opacity-100 focus-within:h-auto focus-within:opacity-100 flex-col space-y-2 overflow-hidden">
          {options.map(option =>
            option.link ? (
              <Link
                to={option.link}
                title={option.name}
                className="text-start text-base px-4 transition-all transform-gpu duration-200 hover:scale-105 hover:font-bold hover:text-primary focus-visible:scale-105 focus-visible:font-bold focus-visible:underline focus-visible:text-primary overflow-hidden outline-none"
              >
                {option.name}
              </Link>
            ) : (
              <button className="text-start text-base px-4 transition-all transform-gpu duration-200 hover:scale-105 hover:font-bold hover:text-primary focus-visible:scale-105 focus-visible:font-bold focus-visible:underline focus-visible:text-primary overflow-hidden outline-none">
                {option.name}
              </button>
            )
          )}
        </div>
      )}
    </MegaTag>
  )
}

export default MobileMenuItem
