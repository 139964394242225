import React from "react"
import { Link } from "gatsby"

const MenuItem = ({ text, link, options, outer }) => {
  const Tag = link
    ? outer
      ? ({ to, title, className, children }) => (
          <a
            href={to}
            title={title}
            className={className}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        )
      : Link
    : ({ children, className }) => (
        <button className={className}>{children}</button>
      )
  return (
    <div className="group relative flex flex-col">
      <Tag
        to={link}
        title={text}
        className="group-hover:font-semibold focus:font-semibold outline-none transition transform-gpu duration-200 ease-in-out group-hover:text-primary focus:text-primary active:text-disco cursor-pointer my-auto"
      >
        {text}
        <span className="block border-b-2 border-primary transition transform-gpu -translate-x-1/2 group-hover:translate-x-0 group-focus-within:translate-x-0 duration-200 ease-in scale-x-0 group-hover:scale-x-100 group-focus-within:scale-100 active:border-disco"></span>
      </Tag>
      {options && (
        <div className="absolute -mt-8 w-max flex mx-auto self-center justify-center">
          <div className="items-start transition transform-gpu duration-200 opacity-0 group-hover:opacity-100 group-hover:translate-y-full group-focus-within:opacity-100 group-focus-within:translate-y-full bg-white rounded-lg border-2 border-primary mx-auto py-2 px-4 space-y-2 flex flex-col shadow-solid-md">
            {options.map(option =>
              option.link ? (
                <Link
                  to={option.link}
                  className="text-base transition transform-gpu duration-200 hover:font-semibold hover:text-primary focus-visible:font-semibold focus-visible:text-primary focus-visible:underline outline-none hover:scale-105"
                >
                  {option.name}
                </Link>
              ) : (
                <button className="text-base transition transform-gpu duration-200 hover:font-semibold hover:text-primary focus-visible:font-semibold focus-visible:text-primary focus-visible:underline outline-none hover:scale-105">
                  {option.name}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default MenuItem
